import React, { useLayoutEffect } from "react";
import { useSetRecoilState } from "recoil";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { ChangePassword, Credentials, ErrorBoundary, Login, ForgotPassword, Callback, Error, PageTitle, Logout, LogoutCallback } from "./components";
import usePageTracking from "./shared/usePageTracking";
import { redirectUrlState, showForgotPasswordState, clientState, showChangeUsernameState } from "./shared/state";
import { EnforcedQueryParamRoute, mapQueryParameterToClientType } from "./shared/utils";
import { getEnvironmentSuffix } from "shared/get-environment";
import { ClientType } from "models/ClientType";
import { reportUIEvent } from "./shared/analytics";
import "./App.scss";

const RouterSwitch: React.FC = () => {
    const changePasswordRequiredQueryParams = ["accessToken"];
    const logoutRequiredQueryParams = ["accessToken", "redirectUrl"];
    usePageTracking();

    return (
        <Switch>
            <Route path="/credentials" component={Credentials} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <EnforcedQueryParamRoute path="/change-password" requiredQueryParams={changePasswordRequiredQueryParams}>
                <ChangePassword />
            </EnforcedQueryParamRoute>
            <Route path="/force-change-password" component={ChangePassword} />
            <EnforcedQueryParamRoute path="/logout" requiredQueryParams={logoutRequiredQueryParams}>
                <Logout />
            </EnforcedQueryParamRoute>
            <Route path="/callback" component={Callback} />
            <Route path="/logout-callback" component={LogoutCallback} />
            <Route path="/error" component={Error} />
            <Route exact path="/" component={Login} />
        </Switch>
    );
};

const App: React.FC = () => {
    const envSuffix = getEnvironmentSuffix();
    const universalLoginRoot = `https://signin${envSuffix}.iqmetrix.net`;
    const rqDefaultCallbackUrl = `${universalLoginRoot}/callback`;
    const queryParams = new URLSearchParams(window.location.search);
    const setShowForgotPasswordState = useSetRecoilState(showForgotPasswordState);
    const setClientState = useSetRecoilState(clientState);
    const setRedirectUrlState = useSetRecoilState(redirectUrlState);
    const setChangeUsernameState = useSetRecoilState(showChangeUsernameState);

    useLayoutEffect(() => {
        const client = mapQueryParameterToClientType();
        setClientState(client);
        setRedirectUrlFromParamOrDefault(client);
        if (client !== ClientType.web) {
            setNonWebViewParams();
        }
        reportUIEvent("User", "Loaded Universal Login", client, true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setNonWebViewParams = () => {
        setShowForgotPasswordState(false);
        if (queryParams.has("lockUsername")) {
            setChangeUsernameState(queryParams.get("lockUsername") !== "true");
        }
    };

    const setRedirectUrlFromParamOrDefault = (client : ClientType) => {
        setRedirectUrlState(queryParams.get("redirectUrl") || `${rqDefaultCallbackUrl}?client=${client}`);
    };

    return (
        <ErrorBoundary>
            <PageTitle />
            <Router>
                <RouterSwitch />
            </Router>
        </ErrorBoundary>
    );
};

export default App;
