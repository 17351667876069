import { Button, Input, Typography, Space, Alert } from "@iqmetrix/antd";
import { PaddingBaseBase } from "@iqmetrix/style-tokens";
import { useRecoilState, useRecoilValue } from "recoil";
import { doAuthConfig } from "./../../providers/auth-provider/AuthProvider";
import { clientKeyState, redirectUrlState, usernameState, isUsernameSetByParamState, clientState, credentialAlertMsgState } from "../../shared/state";
import { buttonDisabledState, enterPasswordState } from "./state";
import { reportUIEvent } from "../../shared/analytics";
import { LoginConfigurationResource } from "models";
import { useHistory } from "react-router-dom";
import { StyledForm, StyledInputFormItem, StyledSpace, StyledRightAlignCol, StyledAlert } from "components/custom-styled-components/CustomStyledComponents";
import { redirectExternalUrl } from "../../shared/utils/redirect-to-external";
import { isUsernameValidEmail } from "../../shared/utils";
import React, { useEffect } from "react";
import { useLocalizedMessage, useParseClientQueryParam, useParseClientKeyQueryParam, useParseRedirectUrlQueryParam, useRedirectToHubIfMissingQueryParams } from "../../hooks";
import { getDomainFromUsername } from "../../shared/get-domain";
import { ViewModeHandler } from "../view-mode-handler";

const { Text, Title } = Typography;

export const Login: React.FC = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useRecoilState(buttonDisabledState);
    const [enterPasswordStatus, setEnterPasswordState] = useRecoilState(enterPasswordState);
    const [username, setUserInput] = useRecoilState(usernameState);
    const [credentialAlertMsg] = useRecoilState(credentialAlertMsgState);
    const [isUsernameSetByParam, setIsUsernameSetByParam] = useRecoilState(isUsernameSetByParamState);
    const clientKey = useRecoilValue(clientKeyState);
    const redirectUrl = useRecoilValue(redirectUrlState);
    const client = useRecoilValue(clientState);
    const history = useHistory();

    const parseClientQueryParam = useParseClientQueryParam();
    const parseClientKeyQueryParam = useParseClientKeyQueryParam();
    const parseRedirectUrlQueryParam = useParseRedirectUrlQueryParam();
    const redirectToHubIfMissingQueryParams = useRedirectToHubIfMissingQueryParams();

    const login = async () => {
        setEnterPasswordState("gettingAuthDetails");

        reportUIEvent("User", "Clicked Sign in.Next", getDomainFromUsername(username));

        const result = await doAuthConfig(username, clientKey, redirectUrl);
        if (result.hasError) {
            setIsButtonDisabled(false);
            setError();
        }
        else
        {
            const isRedirect = handleLoginConfig(result.value);
            if (!isRedirect) {
                reportUIEvent("User", "Retrieved Login Configuration", "Hub", true);
                const queryParams = new URLSearchParams(window.location.search);
                let credentialsUrl = "/credentials";
                if (queryParams.toString() !== "") {
                    credentialsUrl += `?${queryParams}`;
                }
                history.push(credentialsUrl);
            } else {
                reportUIEvent("User", "Retrieved Login Configuration", "OpenId", true);
            }
        }
    };

    const setError = () => {
        setEnterPasswordState("error");
    };

    const isValidInput = (input: string) => input && input.length !== 0;

    const inputChanged = (event: any) => {
        trimAndSetUsername(event.target.value);
    };

    const trimAndSetUsername = (untrimmedUsername: string) => {
        const trimmedInput = untrimmedUsername.trim();
        setIsButtonDisabled(!isValidInput(trimmedInput));
        setUserInput(trimmedInput);
    };

    const handleRedirect = (authenticationUrl: string, username: string): boolean => {
        const urlWithLoginHint = new URL(authenticationUrl);
        if (isUsernameValidEmail(username)) urlWithLoginHint.searchParams.append("login_hint", username);

        redirectExternalUrl(urlWithLoginHint.toString());
        return true;
    }

    const handleLoginConfig = (loginConfig: LoginConfigurationResource): boolean => {
        switch (loginConfig?.protocol) {
            case "Okta":
                return handleRedirect(loginConfig.authenticationUrl, username);
            default: {
                switch (loginConfig?.loginType) {
                    default: //Unknown, treat like iQ
                    case 0: //iQ login
                        return false;
                    case 1: { //OpenId login
                        return handleRedirect(loginConfig.authenticationUrl, username);
                    }
                }
            }
        }
    };

    const handleAlertMessage = () => {
        if (enterPasswordStatus === "error") {
            return (
                <Alert
                    style={{ marginBottom: PaddingBaseBase }}
                    data-testid="enterPasswordErrorMsg"
                    type="error"
                    message="The authentication service isn't available. Please try again or contact your administrator"
                />
            );
        }

        return <Space />;
    };

    // This will run on component mount
    useEffect(() => {
        redirectToHubIfMissingQueryParams();
        parseClientQueryParam();
        parseClientKeyQueryParam();
        parseRedirectUrlQueryParam();

        const queryParams = new URLSearchParams(window.location.search);
        const usernameParam = queryParams.get("username") || "";
        trimAndSetUsername(usernameParam);
        if (usernameParam) {
            setIsUsernameSetByParam(true);
            setIsButtonDisabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // This will run when isUsernameSetByParam changes
    useEffect(() => {
        if (isUsernameSetByParam && username) {
            login();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isUsernameSetByParam]);



    return (
        <ViewModeHandler client={client}>
            <StyledForm layout={"vertical"} onFinish={login}>
                <Title>
                    {useLocalizedMessage("Login.Sign_in_Title")}
                </Title>
                {credentialAlertMsg && <StyledAlert type={credentialAlertMsg.type} message={credentialAlertMsg.message} data-testid="msgAlert" />}
                {handleAlertMessage()}
                <StyledSpace direction="vertical" size="large">
                    <Text>{useLocalizedMessage("Login.Username_or_Email")}</Text>
                    <StyledInputFormItem>{<Input onChange={inputChanged} value={username} data-testid="usernameInput" name="username" autoComplete="username" autoFocus />}</StyledInputFormItem>
                    <StyledRightAlignCol span={28}>
                        <Button loading={enterPasswordStatus === "gettingAuthDetails"} type="primary" disabled={isButtonDisabled} htmlType="submit">
                        {useLocalizedMessage("Login.Next")}
                        </Button>
                    </StyledRightAlignCol>
                </StyledSpace>
            </StyledForm>
        </ViewModeHandler>
    );
};

export default Login;
