import { atom } from 'recoil';
import { setUsernameToReport } from 'shared/analytics';

export const usernameState = atom({
    key: 'username', // unique ID (with respect to other atoms/selectors)
    default: '', // default value (aka initial value)
    effects_UNSTABLE: [
        ({onSet}) => {
            onSet(newUsername => {
                if (typeof(newUsername) === "string") {
                    setUsernameToReport(newUsername);
                }
            });
        }
    ]
});