import React, { useEffect } from "react";
import { Input, Typography, EyeTwoTone, EyeInvisibleOutlined, Row, Col, Form, Alert, Space } from "@iqmetrix/antd";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
    clientState,
    clientKeyState,
    credentialAlertMsgState,
    forceChangePasswordState,
    redirectUrlState,
    showChangeUsernameState,
    showForgotPasswordState,
    usernameState,
    loginAlertMsgState,
    userIdState,
} from "../../shared/state";
import { doCredentialLogin, AUTH_FORCE_CHANGE_PASSWORD_ERROR_CODE, AUTH_USER_LOCKED_ERROR_CODE } from "./../../providers/auth-provider/AuthProvider";
import { reportUIEvent } from "../../shared/analytics";
import { useHistory } from "react-router-dom";
import {
    StyledAlert,
    StyledChangeButton,
    StyledForgotPasswordButton,
    StyledForm,
    StyledPasswordFormItem,
    StyledRightAlignCol,
    StyledSignInButton,
    StyledTitle,
} from "components/custom-styled-components/CustomStyledComponents";
import { PaddingBaseBase } from "@iqmetrix/style-tokens";
import { redirectExternalUrl } from "../../shared/utils/redirect-to-external";
import { useLocalizedMessage } from "../../hooks";
import { getDomainFromUsername } from "../../shared/get-domain";
import { enterPasswordState } from "../login/state";
import { ViewModeHandler } from "../view-mode-handler";
import { HttpErrorResult, HttpObjectResult, LockReasonInformation, UserIdInformation } from "models/http/http";
import { TokenAndUserIdResource } from "models";

const { Text, Title } = Typography;

export const buttonDisabledState = atom({
    key: "isSignInButtonDisabled",
    default: true,
});

export const loadingBtnState = atom({
    key: "credentialsLoadingBtnState",
    default: false,
});

export const Credentials: React.FC = () => {
    const setEnterPasswordState = useSetRecoilState(enterPasswordState);
    const [isButtonDisabled, setIsButtonDisabled] = useRecoilState(buttonDisabledState);
    const [isLoading, setIsLoading] = useRecoilState(loadingBtnState);
    const showForgotPasswordBtn = useRecoilValue(showForgotPasswordState);
    const username = useRecoilValue(usernameState);
    const [password, setPassword] = React.useState("");
    const [credentialAlertMsg, setCredentialAlertMsg] = useRecoilState(credentialAlertMsgState);
    const showChangeUsernameBtn = useRecoilValue(showChangeUsernameState);
    const clientKey = useRecoilValue(clientKeyState);
    const redirectUrl = useRecoilValue(redirectUrlState);
    const client = useRecoilValue(clientState);
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const setForceChangePasswordState = useSetRecoilState(forceChangePasswordState);
    const setUserId = useSetRecoilState(userIdState);
    const [loginAlertMsg, setLoginAlertMsg] = useRecoilState(loginAlertMsgState);

    const invalidCredentialsErrorLine1 = useLocalizedMessage("Credentials.Error.The_credentials_you_have_entered_are_incorrect");
    const invalidCredentialsErrorLine2 = useLocalizedMessage("Credentials.Error.Please_try_again");
    const forgotPassword = useLocalizedMessage("Credentials.Forgot_password");
    const changeUsername = useLocalizedMessage("Credentials.Username_change");
    const userLockedMessage = useLocalizedMessage("Credentials.Error.User_is_locked");
    const userLockedTitle = useLocalizedMessage("Credentials.Error.UserLockedTitle");

    let loginUrl = "/";
    if (queryParams.toString() !== "") {
        loginUrl += `?${queryParams}`;
    }

    useEffect(() => {
        setIsLoading(false);
        if (!username) {
            history.push(loginUrl);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangeClick = () => {
        if (queryParams.has("username")) {
            queryParams.delete("username");
            window.location.search = queryParams.toString();
        }
        setLoginAlertMsg("");
        setCredentialAlertMsg(null);
        setEnterPasswordState("none");
        reportUIEvent("User", "Clicked Change Username", getDomainFromUsername(username));
        history.push(loginUrl);
    };

    const onForgotPassword = () => {
        setCredentialAlertMsg(null);
        reportUIEvent("User", "Clicked Forgot Password", getDomainFromUsername(username));
        history.push(`/forgot-password?${queryParams}`);
    };

    const onPasswordInput = (evt: React.ChangeEvent<HTMLInputElement>) => {
        const input = evt.target.value;
        const isValid = input && input.length !== 0;
        setIsButtonDisabled(!isValid);
        setPassword(input);
    };

    const forceChangePassword = () => {
        setForceChangePasswordState(true);
        setEnterPasswordState("none");
        history.push(`/force-change-password?${queryParams}`);
    };

    const setUserLocked = (msg: string) => {
        setCredentialAlertMsg({
            type: "error",
            message: (
                <>
                    <StyledTitle>{userLockedTitle}</StyledTitle>
                    { msg || userLockedMessage }
                </>
            )
        });
    };

    const setShowErrorMessage = () => {
        setCredentialAlertMsg({
            type: "error",
            message: (
                <>
                    {invalidCredentialsErrorLine1}
                    <br />
                    {invalidCredentialsErrorLine2}
                </>
            ),
        });
    };

    const signin = async () => {
        setIsLoading(true);
        reportUIEvent("User", "Clicked Sign in", getDomainFromUsername(username));

        const loginResult = await doCredentialLogin(username, password, clientKey, redirectUrl);

        if (loginResult.hasError) {
            const error = loginResult as HttpErrorResult;
            if (error) {
                const errorType = error.errorType;
                if (errorType && errorType.length > 0) {
                    setShowErrorMessage();
                    setIsLoading(false);
                    if (errorType[0].type === AUTH_FORCE_CHANGE_PASSWORD_ERROR_CODE) {
                        const info = errorType[0].information as UserIdInformation;
                        if (info && info.userId) {
                            setUserId(info.userId);
                        }
                        forceChangePassword();
                    }
                    else if (errorType[0].type === AUTH_USER_LOCKED_ERROR_CODE) {
                        const info = errorType[0].information as LockReasonInformation;
                        setUserLocked(info.lockReasonDescription);
                    }
                }
            }
        } else {
            const value = (loginResult as HttpObjectResult<TokenAndUserIdResource>).value;
            const redirectUrlWithTokenAndUserIdResource = generateRedirectUrlWithTokenAndUserIdResource(value.redirectUrl, value.token, value.refreshToken, value?.userId);
            redirectExternalUrl(redirectUrlWithTokenAndUserIdResource);
        }
    };

    const generateRedirectUrlWithTokenAndUserIdResource = (redirectUrl: string, token: string, refreshToken: string, userId?: number): string => {
        const decodedUrl = decodeURIComponent(redirectUrl);
        const result = new URL(decodedUrl);
        result.searchParams.append("accessToken", token);
        result.searchParams.append("refreshToken", refreshToken);
        if (userId) {
            result.searchParams.append("userId", userId.toString());
        }
        return result.toString();
    };

    const handleAlertMessage = () => {
        if (loginAlertMsg) {
            return <Alert style={{ marginBottom: PaddingBaseBase }} data-testid="loginAlertMsg" type="warning" message={loginAlertMsg} />;
        }

        return <Space />;
    };

    return (
        <ViewModeHandler client={client}>
            <StyledForm layout={"vertical"} onFinish={signin}>
                <Title>{useLocalizedMessage("Credentials.Sign_in_title")}</Title>
                {credentialAlertMsg && <StyledAlert type={credentialAlertMsg.type} message={credentialAlertMsg.message} data-testid="msgAlert" />}
                {handleAlertMessage()}
                <Row>
                    <Col span={16}>
                        <Text>{username}</Text>
                    </Col>
                    {showChangeUsernameBtn && (
                        <StyledRightAlignCol span={8}>
                            <StyledChangeButton data-testid="changeUsername" type="link" onClick={onChangeClick}>
                                {changeUsername}
                            </StyledChangeButton>
                        </StyledRightAlignCol>
                    )}
                    <Input value={username} name="username" autoComplete="username" style={{ display: "none" }} />
                </Row>
                <StyledPasswordFormItem label={useLocalizedMessage("Credentials.Password")}>
                    <Input.Password
                        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                        onChange={onPasswordInput}
                        data-testid="passwordInput"
                        name="password"
                        autoComplete="current-password"
                        autoFocus
                    />
                </StyledPasswordFormItem>
                {showForgotPasswordBtn && (
                    <Form.Item style={{ textAlign: "right" }}>
                        <StyledForgotPasswordButton type="link" onClick={onForgotPassword}>
                            {forgotPassword}
                        </StyledForgotPasswordButton>
                    </Form.Item>
                )}
                <Form.Item wrapperCol={{ span: 16, offset: 12 }}>
                    <StyledRightAlignCol span={28}>
                        <StyledSignInButton loading={isLoading} disabled={isButtonDisabled} type="primary" data-testid="signInBtn" htmlType="submit">
                            {useLocalizedMessage("Credentials.Sign_in_button")}
                        </StyledSignInButton>
                    </StyledRightAlignCol>
                </Form.Item>
            </StyledForm>
        </ViewModeHandler>
    );
};

export default Credentials;
