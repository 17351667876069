import ReactGA from "react-ga";
import { captureMessage, captureException, setUser, Severity } from "@sentry/react";

export const trackPage = (currentPage: string) => {
    ReactGA.set({ currentPage });
    ReactGA.pageview(currentPage);
};

export const reportUIEvent = (categoryName: string, actionName: string, labelValue: string, isNonInteraction = false) => {
    ReactGA.event({
        // Required: A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc
        category: categoryName,
        // Required: A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
        action: actionName,
        // Optional. More precise labelling of the related action. E.g. alongside the 'Added a component' action, we could add the name of a component as the label. E.g. 'Survey', 'Heading', 'Button', etc.
        label: labelValue.toLocaleLowerCase(),
        // Optional. If an event is not triggered by a user interaction, but instead by our code (e.g. on page load), it should be flagged as a nonInteraction event to avoid skewing bounce rate data.
        nonInteraction: isNonInteraction,
    });
};

export const setUsernameToReport = (username: string): void => {
    setUser({ username });
};

export const reportException = (exception: any, additionalData: Record<string, string>) =>
    captureException(exception, (scope) => {
        scope.setExtras(additionalData);
        return scope;
    });

export const reportWarning = (actionName: string, additionalData: Record<string, string>) =>
    captureMessage(actionName, (scope) => {
        scope.setLevel(Severity.Warning);
        scope.setExtras(additionalData);
        return scope;
    });
