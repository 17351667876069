import React, { useEffect } from "react";
import { RecoilState, useRecoilValue } from "recoil";

interface IObserver<T> {
    node: RecoilState<T>;
    onChange: (value: any) => void;
}

export const RecoilObserver = <T extends any>(props: IObserver<T>) => {
    const { node, onChange } = props;

    const value = useRecoilValue(node);
    
    useEffect(() => { onChange(value) }, [onChange, value]);

    return null;
};