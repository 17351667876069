import React from "react";
import ReactDOM from "react-dom";

import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import ReactGA from "react-ga";
import reportWebVitals from "./reportWebVitals";

import App from "./App";
import { getEnvironment } from "./shared/get-environment";

import { RecoilRoot } from "recoil";
import { I18nProvider } from "./components";

import "./index.scss";

ReactGA.initialize("UA-196873696-1");
ReactGA.set({ env: getEnvironment() });

async function init() {
    const info = await import("../package.json");
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        release: info.name + "@" + info.version,
        environment: getEnvironment(),
        attachStacktrace: true,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.1,
    });
}

ReactDOM.render(
    <RecoilRoot>
        <Sentry.ErrorBoundary>
            <React.StrictMode>
                <I18nProvider>
                    <App />
                </I18nProvider>
            </React.StrictMode>
        </Sentry.ErrorBoundary>
    </RecoilRoot>,
    document.getElementById("root"),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
init();
