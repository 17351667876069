import { reportWarning, reportException } from "shared/analytics";
import { HttpErrorInformation, HttpErrorResult, HttpObjectResult, HttpRequest, HttpResult, HttpRedirectResult } from "../../models/http/http";

export interface HttpError {
    message: string;
    errorInformation: HttpErrorInformation[];
    innerError: any;
    stackTrace: string;
}

export const makeHttpCall = async (request: HttpRequest): Promise<HttpResult> => {
    const reqInit = getNewRequestInit(request);
    try {
        const response = await fetch(request.url, reqInit);

        return response.ok ? { hasError: false } : await handleNotOkResponse(request, response);
    } catch (error) {
        reportException(error, {
            url: request.url,
            method: request.method,
        });
        return getFailedResult();
    }
};

export const makeHttpCallWithResponse = async <T>(request: HttpRequest): Promise<HttpResult<T>> => {
    const reqInit = getNewRequestInit(request);
    try {
        const response = await fetch(request.url, reqInit);

        return response.ok ? await handleOkResponse<T>(response) : await handleNotOkResponse(request, response);
    } catch (error) {
        reportException(error, {
            url: request.url,
            method: request.method,
            headers: reqInit.headers?.toString() || "",
        });

        return getFailedResult();
    }
};

const getNewRequestInit = (request: HttpRequest): RequestInit => {
    const reqHeaders = new Headers();
    reqHeaders.append("Content-Type", "application/json");

    if (request.accessToken !== undefined) {
        reqHeaders.append("Authorization", "Bearer " + request.accessToken);
    }

    const reqInit = {
        method: request.method,
        headers: reqHeaders,
        credentials: request.credentials
    } as RequestInit;

    if (request.method === "POST") {
        reqInit.body = request.body;
    }

    return reqInit;
};

const getFailedResult = (): HttpResult => ({ hasError: true });

const handleOkResponse = async <T>(response: Response): Promise<HttpResult<T>> => {
    if (response.redirected) {
        return {
            hasError: false,
            url: response.url,
        } as HttpRedirectResult;
    }

    return {
        hasError: false,
        value: await response.json(),
    } as HttpObjectResult<T>;
};

const handleNotOkResponse = async (request: HttpRequest, response: Response): Promise<HttpResult> => {
    const failedResult = getFailedResult();

    if (response.status !== 400) {
        reportFailedHttpResponse(request, response, "");
        return { ...failedResult, statusCode: response.status, errorType: [], message: "" } as HttpErrorResult;
    }

    try {
        const httpError = (await response.json()) as HttpError;
        return {
            ...failedResult,
            message: httpError.message,
            errorType: httpError.errorInformation,
            statusCode: response.status,
        } as HttpErrorResult;
    } catch (error) {
        reportException(request, { error: (error as any).toString() });
        return failedResult;
    }
};

const reportFailedHttpResponse = (request: HttpRequest, response: Response, responseMessage: string) =>
    reportWarning("HttpRequest", {
        url: request.url,
        method: request.method,
        response: response.status.toString(),
        responseMessage: responseMessage,
    });
