import { getHubUrl } from "./hub";
import { reportUIEvent } from "../analytics";

const sensitiveQueryParams: string[] = ["accessToken"];

export const redirectExternalUrl = (url: string): void => {
    const reportUrl = new URL(url);
    sensitiveQueryParams.forEach((element) => {
        if (reportUrl.searchParams.has(element)) {
            reportUrl.searchParams.delete(element);
        }
    });
    reportUIEvent("Redirect", "Redirect to external URL", reportUrl.toString(), true);

    window.location.assign(url);
};

export const redirectToHubUrl = (): void => {
    const url = window.location.hostname.toLowerCase().includes("cova") ? getHubUrl("covasoft") : getHubUrl("iqmetrix");
    reportUIEvent("Redirect", "Redirect to Hub URL", url, true);
    window.location.assign(url);
};
